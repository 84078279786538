import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import store from '@/store'

Vue.use(Vuetify)

export default new Vuetify({
  rtl: store.getters.isRtl,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#001E4F',
        secondary: '#f88e2d',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
})
