<template>
  <div class="Landing">
    <home-slider :images="images" />

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12">
          <div class="text-center ma-3">
            <h1>تعرف على آلية عمل المنصة</h1>
          </div>
        </v-col>

        <v-col
          cols="12"
          align="center"
          justify="center"
        >
          <v-tabs
            v-model="tab"
            centered
          >
            <v-tab>منفذ الخدمة</v-tab>
            <v-tab>صاحب المشروع</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-col cols="12">
                <video
                  width="320"
                  height="240"
                  controls
                  style="
                border-radius: 20px;
                overflow: hidden;
                position: relative;
              "
                >
                  <source
                    src="movie.mp4"
                    type="video/mp4"
                  >
                  <source
                    src="movie.ogg"
                    type="video/ogg"
                  >
                  Your browser does not support the video tag.
                </video>
              </v-col>
            </v-tab-item>
            <v-tab-item>
              <v-col cols="12">
                <video
                  width="320"
                  height="240"
                  controls
                  style="
                border-radius: 20px;
                overflow: hidden;
                position: relative;
              "
                >
                  <source
                    src="movie.mp4"
                    type="video/mp4"
                  >
                  <source
                    src="movie.ogg"
                    type="video/ogg"
                  >
                  Your browser does not support the video tag.
                </video>
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-card-actions class="justify-center">
          <v-col cols="12">
            <v-btn
              x-large
              :to="{ name: 'start' }"
              type="submit"
              color="primary"
            >
              ابدأ الآن
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HomeSlider from '@/components/home/HomeSlider'

export default {
  name: 'HomeView',
  components: { HomeSlider },

  data () {
    return {
      tab: 0,
      images: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
        },
      ],
    }
  },
}
</script>
