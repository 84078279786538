<template>
  <v-app>
    <app-bar />
    <app-navigation />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppBar from '@/components/layout/AppBar'
import AppNavigation from '@/components/layout/AppNavigation'

export default {
  name: 'App',
  components: {
    AppNavigation,
    AppBar,
  },

  data: () => ({
    //
  }),

  created () {
    this.$i18n.locale = this.$store.getters.getCurrentLocale
    this.$vuetify.rtl = this.$store.getters.getCurrentLocale === 'ar'
  },
}
</script>
