import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '@/store'
import router from '@/router'

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : '/api/'

axios.interceptors.request.use(config => {
  if (config.method === 'get') {
    return config
  }

  if (process.env.NODE_ENV === 'development') {
    return config
  }

  const csrfToken = document.cookie.split(';').find(cookie => cookie.trim().startsWith('XSRF-TOKEN='))

  if (csrfToken) {
    config.headers['X-XSRF-TOKEN'] = csrfToken.split('=')[1].replace('%3D', '')
  }

  return config
})
axios.interceptors.response.use(undefined, err => {
  const error = err.response
  // if error is 401
  if (error.status === 401) {
    // invalidate state
    store.commit('logout')
    router.push({
      name: 'login',
      query: { redirect_to: router.currentRoute.path },
    })
  }

  return Promise.reject(err)
})

Vue.use(VueAxios, axios)
