<template>
  <v-navigation-drawer
    v-model="$store.state.sidebar"
    app
    :disable-resize-watcher="true"
  >
    <v-list
      nav
    >
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :exact="item.exact"
        link
        :to="item.route"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template #append>
      <v-list
        nav
        dense
      >
        <v-list-item
          class="mb-4"
          link
        >
          <v-list-item-content>الابلاغ عن مشكلة</v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="ma-0 pa-0">
            <v-list-item-title>
              تواصل معنا
            </v-list-item-title>
            <v-list-item
              v-for="item in items2"
              :key="item.title"
              dense
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.getters.isLoggedIn">
          <v-list-item-content>
            <form @submit.prevent="logout">
              <v-btn
                block
                color="primary"
                type="submit"
              >
                <v-icon v-text="'mdi-logout'" />
                {{ $t('auth.logout') }}
              </v-btn>
            </form>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!$store.getters.isLoggedIn"
        >
          <v-btn
            :to="{ name: 'login' }"
            block
            color="primary"
            type="submit"
          >
            <v-icon v-text="'mdi-logout'" />
            {{ $t('auth.logout') }}
          </v-btn>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'AppNavigation',

  data () {
    return {
      items: [
        {
          icon: 'mdi-account',
          title: this.$t('auth.account'),
          route: { name: 'home' },
          exact: true,
        },
        {
          icon: 'mdi-door-open',
          title: this.$t('auth.start'),
          route: { name: 'start' },
          exact: true,
        },
        {
          icon: 'mdi-percent',
          title: this.$t('auth.balance'),
          route: { name: 'funds' },
          exact: true,
        },
      ],

      items2: [
        {
          icon: 'mdi-phone',
          title: '099999999',
        },
        {
          icon: 'mdi-phone',
          title: '0111111111',
        },
        {
          icon: 'mdi-home',
          title: 'Damascus - Syria',
        },
      ],

    }
  },

  methods: {
    logout () {
      const self = this

      self.axios.post('/logout').then(() => {
        self.$store.commit('logout')
        self.$router.push({ name: 'home' })
      })
    },
  },
}
</script>
