<template>
  <v-carousel
    height="400px"
    hide-delimiters
    cycle
  >
    <v-carousel-item
      v-for="(item, i) in images"
      :key="i"
      :src="item.src"
    />
  </v-carousel>
</template>

<script>
export default {
  props:
    {
      images: {
        type: Array,
        default: () => ([]),
      },
    },
}
</script>
