import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: 'ar',
    sidebar: false,
    loggedIn: null,
  },
  getters: {
    isRtl (state) {
      return state.locale === 'ar'
    },

    getCurrentLocale (state) {
      return state.locale
    },

    isLoggedIn (state) {
      return !!state.loggedIn
    },
  },
  mutations: {
    updateLocale (state, locale) {
      state.locale = locale
    },

    updateRtl (state, value) {
      state.rtl = value
    },

    login (state) {
      state.loggedIn = true
    },

    logout (state) {
      state.loggedIn = null
    },

    toggleSidebar (state) {
      state.sidebar = !state.sidebar
    },
  },
  actions: {
    login ({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        Vue.axios.get('/sanctum/csrf-cookie').then(() => {
          Vue.axios.post('/login', credentials).then(res => {
            commit('login')
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        })
      })
    },

    toggleSidebar ({ commit }) {
      commit('toggleSidebar')
    },
  },
  modules: {},
  plugins: [createPersistedState({
    key: 'ead',
    reducer: (state) => {
      return Object.keys(state)
        .filter(key => key !== 'sidebar')
        // eslint-disable-next-line no-return-assign,no-sequences
        .reduce((res, key) => (res[key] = state[key], res), {})
    },
  })],
})
