<template>
  <v-app-bar
    app
    color="primary"
    dark
  >
    <div
      class="d-flex align-center"
      @click="$router.push({ name: $route.name !== 'start' ? 'start' : 'home' })"
    >
      <v-img
        alt="Vuetify Logo"
        class="shrink mr-2"
        contain
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        transition="scale-transition"
        width="40"
      />

      <v-img
        alt="Vuetify Name"
        class="shrink mt-1 hidden-sm-and-down"
        contain
        min-width="100"
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
        width="100"
      />
    </div>

    <v-spacer />

    <v-app-bar-nav-icon @click="$store.commit('toggleSidebar')" />
  </v-app-bar>
</template>
<script>
export default {
  name: 'AppBar',
}
</script>
