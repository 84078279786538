import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/RegisterView.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/ConfirmLogin',
    name: 'ConfirmLogin',
    component: () => import(/* webpackChunkName: "confirm-password" */ '@/views/ConfirmLogin.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/start',
    name: 'start',
    component: () => import(/* webpackChunkName: "start" */ '@/views/Start.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/tasks/create',
    name: 'tasks.create',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/tasks/CreateTask.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/no-funds',
    name: 'no-funds',
    component: () => import(/* webpackChunkName: "no-funds" */ '@/views/NoFunds.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/charge-funds',
    name: 'charge-funds',
    component: () => import(/* webpackChunkName: "charge-funds" */ '@/views/ChargeFunds.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/charge-confirm',
    name: 'charge-confirm',
    component: () => import(/* webpackChunkName: "charge-confirm" */ '@/views/ChargeConfirm.vue'),

    meta: {
      auth: true,
    },
  },
  {
    path: '/charge-confirm/approve',
    name: 'charge-confirm.approve',
    component: () => import(/* webpackChunkName: "charge-confirm" */ '@/views/ChargeConfirmApprove.vue'),

    meta: {
      auth: true,
    },
  },
  {
    path: '/tasks/create/task-sent',
    name: 'tasks.create.task-sent',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/TaskSent.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/task/:id/execute',
    name: 'task.execute',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/ExecuteTask.vue'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/funds',
    name: 'funds',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/Funds.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/funds/take/method',
    name: 'funds.take.method',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/FundsTakeMethod.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/funds/take/transfer',
    name: 'funds.take.transfer',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/FundsTakeTransfer.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/funds/take/balance',
    name: 'funds.take.balance',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/FundsTakeBalance.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/funds/take/confirm',
    name: 'funds.take.confirm',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/FundsTakeConfirm.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/submit-issue',
    name: 'submit.issue',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/SubmitIssue.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/issue-resolved',
    name: 'issue.resolved',
    component: () => import(/* webpackChunkName: "tasks-create" */ '@/views/IssueResolved.vue'),
    meta: {
      auth: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(function (to, from, next) {
  if (to.meta.auth && !store.getters.isLoggedIn) {
    next({
      name: 'login',
      query: { redirect_to: to.path },
    })
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    next(from ?? 'home')
  }
  next()
})

export default router
